import React, { Fragment } from 'react';
import Hero from '../components/Hero';

const Contact = () => {
	return (
		<Fragment>
			<Hero text="ARE YOU LOOKING TO COLLABORATE? LET'S GET IN TOUCH!" />
		</Fragment>
	);
};

export default Contact;
